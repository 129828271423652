import { VariablesServer } from '~/config/variables.server'

export type VariablesClient = Pick<
  VariablesServer,
  | 'NODE_ENV'
  | 'SENTRY_DSN'
  | 'SENTRY_RELEASE'
  | 'SENTRY_ENVIRONMENT'
  | 'DEACTIVATED_FEATURES'
>

/**
 * Some env variables are exposed to the client side.
 * To achieve this, these variables are attached to the window in the loader of the root component.
 * --> frontend/app/routes/root.tsx
 * */
export const variables: VariablesClient = {
  NODE_ENV: window.ENV?.NODE_ENV || '',
  SENTRY_DSN: window.ENV?.SENTRY_DSN || '',
  SENTRY_ENVIRONMENT: window.ENV?.SENTRY_ENVIRONMENT || '',
  SENTRY_RELEASE: window.ENV?.SENTRY_RELEASE,
  DEACTIVATED_FEATURES: window.ENV?.DEACTIVATED_FEATURES || [],
}
